.bguest__login{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.bguest__login-body{
    width: 25%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 10rem;
}

.bguest__login-form{
    width: 100%;
    height: 100%;
}

/* Small Laptop Media */
@media only screen and (max-width: 1024px) and (min-width: 768px)  {
    .bguest__login-body{
        width: 50%;
    }
}

/* Tablet Media */
@media only screen and (max-width: 768px) and (min-width: 600px)   {
    .bguest__login-body{
        width: 50%;
    }
}

/* Mobile Media */
@media only screen and (max-width: 599px){
    .bguest__login-body{
        width: 90%;
    }
}