.bguest__slideSeven{
  display: flex;
  flex-direction: column;
}

.bguest__slideSeven-body{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 75vh;
    padding-left: 5rem;
    padding-right: 5rem;
}

.bguest__slideSeven-body-contents{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    width: 40%;
    height: 100%;
}

.bguest__slideSeven-body-contents-description{
    display: flex;
    flex-direction: row;
}

.bguest__slideSeven-body-contents-description .description{
    margin-right: 12px;
    margin-top: 8px;
}

.bguest__slideSeven-body-contents-description svg{
    font-size: 20px;
    color: var(--color-black);
    margin-bottom: -1rem;
}

.bguest__slideSeven-body-contents-form{
    margin-top: 1rem;
    width: 80%;
}

.bguest__slideSeven-body-contents-form p{
    margin-top: 1rem;
    font-family: "Poppins";
    font-size: 16px;
    font-weight: 400;
    color: var(--color-black);
    font-weight: lighter;
}

.bguest__slideSeven-body-map{
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-color: white;
    align-items: center;
    width: 50%;
    height: 90%;
    flex-shrink: 0;
    overflow: hidden;
}

.bguest__slideSeven-body-contents-mobile{
    display: none;
}

/* Small Laptop Media */
@media only screen and (max-width: 1024px) and (min-width: 768px)  {
    .bguest__slideSeven-body{
        padding-left: 1rem;
        padding-right: 1rem;
    }

    .bguest__slideSeven-body-contents-form p{
        font-size: 14px;
    }

    .bguest__slideSeven-body-map{
        width: 55%;
        height: 60%;
    }
}

/* Tablet Media */
@media only screen and (max-width: 768px) and (min-width: 600px)   {
    .bguest__slideSeven-body{
        padding-left: 1rem;
        padding-right: 1rem;
    }

    .bguest__slideSeven-body-contents-form p{
        font-size: 12px;
    }

    .bguest__slideSeven-body-map{
        width: 55%;
        height: 60%;
    }
}

/* Mobile Media */
@media only screen and (max-width: 599px){
    .bguest__slideSeven{
        width: 100%;
        height: auto;
        display: flex;
        flex-direction: column;
        overflow-y: scroll;
        overflow-x: hidden;
      }

    .bguest__slideSeven-body{
        width: 100%;
        height: auto;
        padding-left: 1rem;
        padding-right: 1rem;
        display: flex;
        flex-direction: column;
    }

    .bguest__slideSeven-body-contents{
        display: none;
    }

    .bguest__slideSeven-body-contents-mobile{
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        width: 100%;
    }

    .bguest__slideSeven-body-contents-form p{
        font-size: 12px;
    }

    .bguest__slideSeven-body-map{
        width: 100%;
        height: 20rem;
        margin-bottom: 2rem;
        margin-top: 1rem;
    }

    .bguest__slideSeven-footer{
        display: none;
    }
}