.bguest__pricePoint{
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
}

.bguest__pricePoint-icon{
    width: 10%;
    font-size: 18px;
    
}

.bguest__pricePoint h1{
    width: 90%;
    font-family: "Poppins";
    font-size: 1.3rem;
    font-weight: 800;
    color: var(--color-black);
    margin-left: 5px;
}