.bguest__addGuestForm{
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    top: 0;
    width: 100vw;
    height: 100vh;
    padding: 5rem;
    background-color: var(--color-transparent);
    overflow: hidden;
    z-index: 2000;
}

.bguest__addGuestForm-body{
    width: 50vw;
    background-color: white;
    border-radius: 12px;
    display: flex;
    flex-direction: column;
    padding-left: 3rem;
    padding-right: 3rem;
    padding-top: 1rem;
    padding-bottom: 3rem;
}

.bguest__addGuestForm-close-button{
    display: flex;
    align-self: flex-end;
    cursor: pointer;
    color: var(--color-brand);
    font-size: 46px;
    z-index: 2001;
    margin: 1rem;
}

.bguest__addGuestForm-close-button:hover{
    transform: scale(1.1);
    transition: all 0.3s ease-in-out;
    color: var(--color-brand-dark);
}

.bguest__addGuestForm-body-info{
    display: flex;
    flex-direction: column;
}

.bguest__addGuestForm-body-info-input{
    margin-bottom: 3rem;
}

.bguest__addGuestForm-body-info-input .field{
    width: 100%;
    height: 3.5rem;
    border: 1px solid var(--color-bg-dark);
    border-radius: 8px;
    font-size: 16px;
    padding: 1rem;
    outline: none;
    margin-top: 1rem;
}