.bGuest__animated-button-animation{
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    overflow: hidden;
    cursor: pointer;
    margin-right: 1rem;
}

.bGuest__animated-button-animation h1{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: white;
    font-family: "Opensans";
    font-weight: bold;
    text-align: center;
}