.bguest__homepage{
   display: flex;
   width: 100%;
   height: 100%;
}

/* Mobile Media */
@media only screen and (max-width: 599px){
   .bguest__homepage{
      min-height: 100%;
   }
   
}
