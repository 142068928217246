.bguest__guestAnalysis{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
}

.bguest__guestAnalysis-score-cards{
    display: flex;
    flex-direction: row;
    margin-top: 1rem;
}

.bguest__guestAnalysis-score-cards .score-card{
    margin-right: 1rem;
}

.bguest__guestAnalysis-graph{
    margin-top: 2rem;
    display: flex;
    flex-direction: column;
    background-color: white;
    border-radius: 12px;
    padding: 1rem;
}

.bguest__guestAnalysis-graph-banner{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 2rem;
}

.bguest__guestAnalysis-graph-banner-title-dropdown{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.bguest__guestAnalysis-graph-banner-dropdown{
    margin-left: 1rem;
}

.bguest__guestAnalysis-graph-banner-toggle{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-right: 3rem;
}

.bguest__guestAnalysis-graph-banner-toggle h1{
    padding-top: 0;
    display: flex;
    align-self: center;
    margin-right: 8px;
}
