.bguest__footerSecondary{
    width: 100vw;
    min-height: 15vh;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding-left: 10rem;
    padding-right: 10rem;
    padding-top: 2rem;
    padding-bottom: 2rem;
    background-color: var(--color-black);
}

.bguest__footerSecondary-logo img{
    width: 10vw;
}

.bguest__footerSecondary-permalinks{
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: center;
}

.bguest__footerSecondary-permalinks-social-icons{
    display: flex;
    flex-direction: row;
}

.bguest__footerSecondary-permalinks-social svg{
    font-size: 32px;
    color: var(--color-menu-text);
    margin-top: 1rem;
    margin-right: 12px;
    cursor: pointer;
    opacity: 0.8;
}

.bguest__footerSecondary-permalinks-social svg:hover{
    color: var(--color-brand);
}

.bguest__footerSecondary-permalinks-extras{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-top: 2rem;
}

.bguest__footerSecondary-permalinks-extras h1{
    color: white;
    margin-right: 3rem;
    font-size: 16px;
    font-family: "Poppins";
}

.bguest__footerSecondary-permalinks-extras p{
    color: white;
    margin-left: 1rem;
    font-size: 16px;
    font-family: "Poppins";
    text-decoration: none;
    cursor: pointer;
}

.bguest__footerSecondary-permalinks-extras p:hover{
    color: var(--color-brand);
}

/* Small Laptop Media */
@media only screen and (max-width: 1024px) and (min-width: 768px)  {
    .bguest__footerSecondary{
        padding-left: 2rem;
        padding-right: 2rem;
    }

    .bguest__footerSecondary-logo img{
        width: 15vw;
    }

    .bguest__footerSecondary-permalinks-extras h1{
        margin-right: 1rem;
    }
}

/* Tablet Media */
@media only screen and (max-width: 768px) and (min-width: 600px)   {
    .bguest__footerSecondary{
        padding-left: 1rem;
        padding-right: 1rem;
    }

    .bguest__footerSecondary-logo img{
        width: 15vw;
    }

    .bguest__footerSecondary-permalinks-extras h1{
        margin-right: 1rem;
        font-size: 12px;
        text-align: end;
    }
    
    .bguest__footerSecondary-permalinks-extras p{
        font-size: 12px;
    }
}

/* Mobile Media */
@media only screen and (max-width: 599px){
    .bguest__footerSecondary{
        flex-direction: column;
        justify-content: center;
        padding-left: 1rem;
        padding-right: 1rem;
    }

    .bguest__footerSecondary-logo img{
        width: 10rem;
        align-self: center;
    }

    .bguest__footerSecondary-permalinks{
        align-items: center;
    }

    .bguest__footerSecondary-permalinks-extras{
        flex-direction: column;
    }

    .bguest__footerSecondary-permalinks-extras h1{
        margin-right: 0;
        font-size: 12px;
        text-align: center;
    }
    
    .bguest__footerSecondary-permalinks-extras p{
        font-size: 12px;
        margin-left: 0;
        margin-top: 8px;
    }
}

