.bguest__imageInfoBox{
    width: 20vw;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    overflow: hidden;
    padding: 2rem;
    background-color: white;
    border-radius: 8px;
    margin: 8px;
}

.bguest__imageInfoBox-Image svg{
    display: flex;
    width: 60px;
    height: 60px;
    color: var(--color-brand);
}

.bguest__imageInfoBox-title{
    width: 100%;
    font-family: "Josephine";
    font-size: 10px;
    font-weight: 600;
    color: var(--color-black);
    padding-top: 1rem;
}
.bguest__imageInfoBox-description{
    width: 100%;
    font-family: "Poppins";
    font-size: 8px;
    color: var(--color-black);
    padding-top: 1rem;
    font-weight: lighter;
}

/* Small Laptop Media */
@media only screen and (max-width: 1024px) and (min-width: 768px)  {
    .bguest__imageInfoBox{
        width: 30vw;
    }
}

/* Tablet Media */
@media only screen and (max-width: 768px) and (min-width: 600px)   {
    .bguest__imageInfoBox{
        width: 30vw;
        align-items: center;
    }
}

/* Mobile Media */
@media only screen and (max-width: 599px){
    .bguest__imageInfoBox{
        width: 80%;
        align-items: center;
    }


.bguest__imageInfoBox-Image svg{
    width: 40px;
    height: 40px;
}

.bguest__imageInfoBox-description{
    font-size: 7px;
}
}