.bguest__premiumPricing{
    width: 20vw;
    display: flex;
    flex-direction: column;
    border: 0.5px solid var(--color-grey);
    padding-left: 1rem;
    padding-right: 1rem;
    padding-top: 2rem;
    padding-bottom: 2rem;
    border-radius: 20px;
    background-color: white;
    position: relative;
}

.bguest__premiumPricing-button-tablet{
    display: none;
}

.bguest__premiumPricing-tag {
    width: 100px;
    position: absolute;
    top: -5px;
    right: 5px;
    object-fit: contain;
}

.bguest__premiumPricing h2{
    font-family: "Josephine";
    font-size: 22px;
}

.bguest__premiumPricing h3{
    font-family: "Poppins";
    font-size: 16px;
    color: var(--color-grey);
}

.bguest__premiumPricing-price{
    margin-top: 1.5rem;
}

.bguest__premiumPricing-price p{
    font-family: "Poppins";
    font-size: 40px;
    font-weight: bold;
}

.bguest__premiumPricing-button{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 1.5rem;
}

.bguest__premiumPricing-features{
    margin-top: 1.5rem;
}

.bguest__premiumPricing-features h1{
    font-size: 16px;
}

.bguest__premiumPricing-features .feature{
    margin-top: 8px;
}

/* Small Laptop Media */
@media only screen and (max-width: 1024px) and (min-width: 768px)  {
    .bguest__premiumPricing{
        width: 30vw;
    }

    .bguest__premiumPricing-tag {
        width: 70px;
    }
}

/* Tablet Media */
@media only screen and (max-width: 768px) and (min-width: 600px)   {
    .bguest__premiumPricing{
        width: 30vw;
    }

    .bguest__premiumPricing-button{
        display: none;
    }

    .bguest__premiumPricing-button-tablet{
        display: block;
        padding-top: 1rem;
    }

    .bguest__premiumPricing-tag {
        width: 70px;
    }

    .bguest__premiumPricing h2{
        font-size: 20px;
    }
    
    .bguest__premiumPricing h3{
        font-size: 14px;
    }

    .bguest__premiumPricing-price p{
        font-size: 34px;
    }

    .bguest__premiumPricing-features h1{
        font-size: 14px;
    }
}

/* Mobile Media */
@media only screen and (max-width: 599px){
    .bguest__premiumPricing{
        width: 80vw;
    }

    .bguest__premiumPricing-button{
        display: none;
    }

    .bguest__premiumPricing-button-tablet{
        display: block;
        padding-top: 1rem;
    }

    .bguest__premiumPricing-tag {
        width: 70px;
    }

    .bguest__premiumPricing h2{
        font-size: 20px;
    }
    
    .bguest__premiumPricing h3{
        font-size: 14px;
    }

    .bguest__premiumPricing-price p{
        font-size: 34px;
    }

    .bguest__premiumPricing-features h1{
        font-size: 14px;
    }
}