.bguest__title h1{
    font-family: "Josephine";
    font-size: 4em;
    font-weight: bold;
    color: var(--color-black);
   
}
.bguest__title .highlight {
    color: var(--color-tagline);
  }

/* Small Laptop Media */
@media only screen and (max-width: 1024px) and (min-width: 768px) {
    .bguest__title h1{
        font-size: 3em;
    }
}

/* Tablet Media */
@media only screen and (max-width: 768px) and (min-width: 600px) {
    .bguest__title h1{
        font-size: 2.5em;
    }
}

/* Mobile Media */
@media only screen and (max-width: 599px){
    .bguest__title h1{
        font-size: 2em;
    }
}