.bguest__placeholder {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 1rem;
   
}

.bguest__placeholder-loading{
    font-size: 27px;
    color: var(--color-brand);
}