/* Product about Full Body */

.bguest__about{
    display: flex;
    flex-direction: column;
    width: 100%;
    min-height: 100vh;
}

/* First Screen Banner */

.bguest__about-banner {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-top: 8rem;
}

.bguest__about-banner-title {
    margin-top: 5rem;
    margin-bottom: 5rem;
}

.bguest__about-banner-pillars{
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: center;
    margin-top: 2rem;
}

.bguest__about-banner-bottom{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 3rem;
}

.bguest__about-banner-bottom-quote h1{
    text-decoration: underline;
    font-weight: 700;
    cursor: pointer;
}

/* Team Dedication Image */

.bguest__about-team {
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
    justify-content: center;
    margin-top: 10rem;
    text-align: center;
}

.bguest__about-team img{
    width: 60%;
    object-fit: cover;
}

/* Mission Statement */

.bguest__about-mission{
    width: 80%;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
    align-self: center;
    margin-top: 10rem;
}

.bguest__about-mission-contents{
    width: 50%;
}

.bguest__about-mission-contents-title h1{
    font-family: "Josephine";
    font-weight: bold;
    font-size: 44px;
}

.bguest__about-mission-contents-description h1{
    line-height: 2.5;
}

.bguest__about-mission-image img{
    width: 400px;
    height: 400px;
}

/* Vision Statement */

.bguest__about-vision{
    width: 80%;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
    align-self: center;
    margin-top: 10rem;
}

.bguest__about-vision-contents{
    width: 50%;
}

.bguest__about-vision-contents-title h1{
    font-family: "Josephine";
    font-weight: bold;
    font-size: 44px;
}

.bguest__about-vision-contents-description h1{
    line-height: 2.5;
}

.bguest__about-vision-image img{
    width: 400px;
    height: 400px;
}

.bguest__about-vision-image-mobile{
    display: none;
}

/* Demo Section */

.bguest__about-demo{
    margin-top: 10rem;
    margin-bottom: 10rem;
}

/* Small Laptop Media */
@media only screen and (max-width: 1024px) and (min-width: 768px)   {
    .bguest__about-team img{
        width: 70%;
    }

    .bguest__about-mission{
        width: 100%;
        padding-left: 1rem;
        padding-right: 1rem;
    }
    
    .bguest__about-mission-contents{
        width: 60%;
    }

    .bguest__about-vision{
        width: 100%;
        padding-left: 1rem;
        padding-right: 1rem;
    }
    
    .bguest__about-vision-contents{
        width: 60%;
    }

    .bguest__about-vision-contents-title h1{
        text-align: end;
    }
    
    .bguest__about-vision-contents-description h1{
        text-align: end;
    }
}

/* Tablet Media */
@media only screen and (max-width: 768px) and (min-width: 600px)    {
    .bguest__about-banner h1{
        text-align: center;
    }

    .bguest__about-banner {
        padding-top: 4rem;
    }

    .bguest__about-banner-title {
        margin-top: 2rem;
        margin-bottom: 2rem;
    }

    .bguest__about-team img{
        width: 70%;
    }

    .bguest__about-mission{
        width: 100%;
        padding-left: 1rem;
        padding-right: 1rem;
    }
    
    .bguest__about-mission-contents{
        width: 60%;
    }

    .bguest__about-vision{
        width: 100%;
        padding-left: 1rem;
        padding-right: 1rem;
    }
    
    .bguest__about-vision-contents{
        width: 60%;
    }

    .bguest__about-vision-contents-title h1{
        text-align: end;
    }
    
    .bguest__about-vision-contents-description h1{
        text-align: end;
    }
}

/* Mobile Media */
@media only screen and (max-width: 599px){
    .bguest__about-banner h1{
        text-align: center;
    }

    .bguest__about-banner {
        padding-top: 3rem;
    }

    .bguest__about-banner-title {
        margin-top: 1rem;
        margin-bottom: 1rem;
    }

    .bguest__about-banner-pillars{
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin-top: 0;
    }

    .bguest__about-banner-bottom{
        width: 90%;
    }

    .bguest__about-team img{
        width: 90%;
    }

    .bguest__about-mission{
        width: 100%;
        padding-left: 1rem;
        padding-right: 1rem;
    }
    
    .bguest__about-mission-contents{
        width: 60%;
    }

    .bguest__about-vision{
        width: 100%;
        padding-left: 1rem;
        padding-right: 1rem;
    }
    
    .bguest__about-vision-contents{
        width: 60%;
    }

    .bguest__about-vision-contents-title h1{
        text-align: end;
    }
    
    .bguest__about-vision-contents-description h1{
        text-align: end;
    } .bguest__about-banner h1{
        text-align: center;
    }

    .bguest__about-banner {
        padding-top: 4rem;
    }

    .bguest__about-banner-title {
        margin-top: 2rem;
        margin-bottom: 2rem;
    }

    .bguest__about-team {
        margin-top: 3rem;
    }

    .bguest__about-team img{
        width: 90%;
    }

    .bguest__about-mission{
        width: 100%;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin-top: 10rem;
    }
    
    .bguest__about-mission-contents{
        width: 100%;
    }
    
    .bguest__about-mission-image img{
        width: 300px;
        height: 300px;
    }

    .bguest__about-vision{
        width: 100%;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin-top: 10rem;
    }
    
    .bguest__about-vision-contents{
        width: 100%;
    }

    .bguest__about-vision-image{
        display: none;
    }

    .bguest__about-vision-image-mobile{
        display: flex;
        margin-top: 2rem;
    }
    
    .bguest__about-vision-image img{
        width: 300px;
        height: 300px;
    }

    .bguest__about-vision-contents-title h1{
        text-align: end;
    }
    
    .bguest__about-vision-contents-description h1{
        text-align: end;
    }
}