.bguest__videoPreview{
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    top: 0;
    width: 100vw;
    height: 100vh;
    padding: 5rem;
    background-color: var(--color-transparent);
    overflow: hidden;
    z-index: 2000;
}

.bguest__videoPreview-body{
    position: relative;
}

.bguest__videoPreview-close-button{
    position: absolute;
    cursor: pointer;
    color: var(--color-brand);
    font-size: 46px;
    z-index: 2001;
    right: 0;
    margin: 1rem;
}

.bguest__videoPreview-close-button:hover{
    transform: scale(1.1);
    transition: all 0.3s ease-in-out;
    color: var(--color-brand-dark);
}

/* Mobile Media */
@media only screen and (max-width: 599px){
    .bguest__videoPreview{
        padding: 5px;
    }

    .bguest__videoPreview-close-button{
        font-size: 32px;
    }
}