.bguest__thumbAnimation{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    overflow: hidden;
    height: 100%;
}

.bguest__thumbAnimation h1{
    width: 100%;
    display: flex;
    text-align: center;
    align-items: center;
    justify-content: center;
    font-family: "Poppins";
    font-size: 14px;
    font-weight: 400;
    color: var(--color-black);
    padding-top: 1rem;
    font-weight: lighter;
}

.bguest__thumbAnimation-animation{
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: white;
    width: 245px;
    height: 170px;
    overflow: hidden;
    border-radius: 8px;
}

.bguest__thumbAnimation-animation .animation {
   width: 150px;
   height: 150px;
}

/* Small Laptop Media */
@media only screen and (max-width: 1024px) and (min-width: 768px)  {
    .bguest__thumbAnimation{
        margin-right: 8px;
        min-width: 180px;
    }
    .bguest__thumbAnimation-animation{
        width: 180px;
        height: 150px;
    }
    .bguest__thumbAnimation-animation .animation {
        width: 120px;
        height: 120px;
     }

     .bguest__thumbAnimation h1{
        font-size: 12px;
    }
}

/* Tablet Media */
@media only screen and (max-width: 768px) and (min-width: 600px)   {
    .bguest__thumbAnimation{
        margin-right: 5px;
        min-width: 100px;
    }
    .bguest__thumbAnimation-animation{
        width: 100px;
        height: 80px;
    }
    .bguest__thumbAnimation-animation .animation {
        width: 80px;
        height: 80px;
     }

     .bguest__thumbAnimation h1{
        font-size: 11px;
    }
}

/* Mobile Media */
@media only screen and (max-width: 599px){
    .bguest__thumbAnimation{
        margin-right: 5px;
        min-width: 150px;
    }
    .bguest__thumbAnimation-animation{
        width: 150px;
        height: 100px;
    }
    .bguest__thumbAnimation-animation .animation {
        width: 100px;
        height: 100px;
     }

     .bguest__thumbAnimation h1{
        font-size: 10px;
    }
}