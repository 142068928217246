* {
    box-sizing: border-box;
    padding: 0;
    margin: 0;
  }
  
  body {
    background: var(--color-bg);
  }

  .bguest__bg{
    width: 100vw;
  }

  /* Mobile Media */
@media only screen and (max-width: 599px){
  body {
    overflow-x: hidden;
  }

  .bguest__bg{
    overflow-x: hidden;
  }
}