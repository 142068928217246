.bguest__button{
    background: var(--color-brand);
    margin-right: 1rem;
    color: white;
    font-family: "Opensans";
    font-weight: bold;
    border: 0.5px solid var(--color-brand);
    cursor: pointer;
}

.bguest__button:hover {
    will-change: transform;
    transform: scale(1.1);
    transition: all 0.3s ease-in-out;
    background: var(--color-brand-dark);
    }