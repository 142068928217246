.bguest__featureBulletRight{
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
}

.bguest__featureBulletRight-icon{
    font-size: 1.3rem;
}

.bguest__featureBulletRight h1{
    font-family: "Poppins";
    text-align: end;
    font-size: 1.3rem;
    font-weight: 800;
    color: var(--color-black);
    margin-right: 5px;
}

/* Small Laptop Media */
@media only screen and (max-width: 1024px) and (min-width: 768px)  {
    .bguest__featureBulletRight h1{
        font-size: 1.1rem;
    }
}

/* Tablet Media */
@media only screen and (max-width: 768px) and (min-width: 600px)   {
    .bguest__featureBulletRight-icon{
        font-size: 0.9rem;
    }
    
    .bguest__featureBulletRight h1{
        font-size: 0.9rem;
    }
}

/* Mobile Media */
@media only screen and (max-width: 599px){
    .bguest__featureBulletRight-icon{
        font-size: 0.9rem;
    }
    
    .bguest__featureBulletRight h1{
        font-size: 0.9rem;
    }
}