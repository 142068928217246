.bguest__dateView{
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    top: 0;
    width: 100vw;
    height: 100vh;
    padding: 5rem;
    background-color: var(--color-transparent);
    overflow: hidden;
    z-index: 2000;
}

.bguest__dateView-body{
    width: 30vw;
    height: 60vh;
    background-color: white;
    border-radius: 12px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-left: 3rem;
    padding-right: 3rem;
    padding-top: 1rem;
    padding-bottom: 2rem;
}

.bguest__dateView-close-button{
    display: flex;
    align-self: flex-end;
    cursor: pointer;
    color: var(--color-brand);
    font-size: 46px;
    z-index: 2001;
    margin: 1rem;
}

.bguest__dateView-close-button:hover{
    transform: scale(1.1);
    transition: all 0.3s ease-in-out;
    color: var(--color-brand-dark);
}

.bguest__dateView-calender{
    margin-bottom: 1rem;
}