.bguest__pricing{
    width: 100vw;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
}

/* Top Content */

.bguest__pricing-top-content{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 5rem;
}

.bguest__pricing-top-content h1{
    text-align: center;
}

/* Toggle Section */

.bguest__pricing-toggle-section{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-top: 5rem;
    margin-bottom: 3rem;
}

.bguest__pricing-toggle-section h1{
    margin-left: 8px;
    margin-right: 8px;
    padding-top: 0;
}

.bguest__pricing-toggle-section .month h1{
    color: var(--color-grey);
}

.bguest__pricing-toggle-section .year h1{
    color: var(--color-grey);
}

.bguest__pricing-toggle-section .discount{
    font-family: "Poppins";
    font-size: 8px;
    background-color: bisque;
    padding: 3px;
    border-radius: 5px;
}

/* Pricing Table */

.bguest__pricing-packages{
    display: flex;
    flex-direction: row;
    margin-bottom: 10rem;
}

.bguest__pricing-packages .package{
    margin: 1rem;
}

/* Customized Order */

.bguest__pricing-packages-customized{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: 10rem;
}

.bguest__pricing-packages-customized-button-animation-tablet{
    display: none;
}

.bguest__pricing-packages-customized-button-animation-mobile{
    display: none;
}

.bguest__pricing-packages-customized-title h1{
    font-size: 3em;
    text-align: center;
}

/* Tablet Media */
@media only screen and (max-width: 768px) and (min-width: 600px) {
    .bguest__pricing-packages .package{
        margin: 5px;
    }

    .bguest__pricing-packages-customized-title h1{
        font-size: 2.5em;
    }

    .bguest__pricing-packages-customized-button-animation{
        display: none;
    }

    .bguest__pricing-packages-customized-button-animation-tablet{
        display: block;
        padding-top: 1rem;
    }
}

/* Mobile Media */
@media only screen and (max-width: 599px){
    .bguest__pricing-packages{
        width: 100%;
        overflow-x: scroll;
        margin-bottom: 5rem;
    }

    .bguest__pricing-packages .package{
        margin: 5px;
    }

    .bguest__pricing-packages-customized-title h1{
        font-size: 2em;
    }

    .bguest__pricing-packages-customized-button-animation{
        display: none;
    }

    .bguest__pricing-packages-customized-button-animation-tablet{
        display: none;
    }

    .bguest__pricing-packages-customized-button-animation-mobile{
        display: block;
        padding-top: 1rem;
    }
}