.bguest__dashboard{
    width: 100%;
    min-height: 100vh;
    display: flex;
    flex-direction: row;
}

.bguest__dashboard-sidebar{
    width: 15%;
    min-height: 100vh;
    border-right: 1px solid var(--color-bg-dark) ;
    padding-top: 3rem;
    padding-left: 2rem;
    padding-right: 1rem;
    display: flex;
    flex-direction: column;
}

.bguest__dashboard-sidebar-logo{
    display: inline-block;
}

.bguest__dashboard-sidebar-logo img{
    height: 40px;
    cursor: pointer;
}

.bguest__dashboard-sidebar-tabs{
    padding-top: 2rem;
    margin-bottom: 3rem;
}

.bguest__dashboard-body{
    width: 85%;
    min-height: 100vh;
    padding-top: 3rem;
    padding-bottom: 2rem;
    padding-left: 2rem;
    padding-right: 1rem;
}