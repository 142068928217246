.bguest__toggle-switch {
    position: relative;
    display: inline-block;
    width: 50px;
    height: 28px;
  }
  .bguest__toggle-switch input[type="checkbox"] {
    display: none;
  }
  .bguest__toggle-switch .switch {
    position: absolute;
    cursor: pointer;
    background-color: #ccc;
    border-radius: 15px;
    display: flex;
    justify-content:flex-start;
    align-items: center;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    transition: background-color 0.2s ease;
  }
  .bguest__toggle-switch .switch::before {
    position: absolute;
    content: "";
    width: 20px;
    height: 20px;
    background-color: #aaa;
    border-radius: 50%;
    transition: transform 0.3s ease;
  }
  .bguest__toggle-switch input[type="checkbox"]:checked + .switch::before {
    transform: translateX(28px);
    background-color: #eaa8a9;
  }
  .bguest__toggle-switch input[type="checkbox"]:checked + .switch {
    background-color: var(--color-brand);
  }

  /* @media screen and (min-width: 1050px){
    .toggle-switch {
      width: 45px;
      height: 25px;
    }
    .toggle-switch .switch::before {
      width: 20px;
      height: 20px;
  
    }
    .toggle-switch input[type="checkbox"]:checked + .switch::before {
      transform: translateX(25px);
      background-color: green;
    }
  } */