.bguest__slideSix {
  display: flex;
  flex-direction: column;
}

.bguest__slideSix-body {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 75vh;
  padding-left: 5rem;
  padding-right: 5rem;
}

.bguest__slideSix-body-contents {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: 40%;
  height: 100%;
}

.bguest__slideSix-body-contents .animation-tag {
  font-family: "Poppins";
  font-size: 14px;
  font-weight: 400;
  color: var(--color-black);
  padding-top: 1rem;
  font-weight: lighter;
}

.bguest__slideSix-body-contents-thumb-animations {
  width: 100%;
  display: flex;
  flex-direction: row;
  overflow: hidden;
  justify-content: space-between;
  margin-top: 1rem;
  border-radius: 8px;
  background-color: white;
}

.bguest__slideSix-body-animation {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-color: white;
  border-radius: 12px;
  width: 50%;
  height: 90%;
  flex-shrink: 0;
  overflow: hidden;
}

.bguest__slideSix-body-animation-man-with-wifi {
  position: relative;
}

.bguest__slideSix-body-animation .wifi-range {
  position: absolute;
  top: -1.5rem;
  left: -1.5rem;
  width: 60px;
  height: 60px;
}

.bguest__slideSix-body-animation .router {
  position: absolute;
  top: -5rem;
  right: -1rem;
  width: 150px;
  height: 150px;
}

.bguest__slideSix-body-animation .image1 {
  height: 200px;
}

.bguest__slideSix-body-animation .image2 {
  height: 500px;
}

.bguest__slideSix-body-contents-mobile{
    display: none;
}

/* Small Laptop Media */
@media only screen and (max-width: 1024px) and (min-width: 768px) {
  .bguest__slideSix-body {
    padding-left: 1rem;
    padding-right: 1rem;
  }

  .bguest__slideSix-body-contents .animation-tag {
    font-size: 12px;
  }

  .bguest__slideSix-body-animation {
    width: 55%;
    height: 70%;
  }

  .bguest__slideSix-body-animation .image1 {
    height: 180px;
  }

  .bguest__slideSix-body-animation .image2 {
    height: 350px;
  }
}

/* Tablet Media */
@media only screen and (max-width: 768px) and (min-width: 600px) {
  .bguest__slideSix-body {
    padding-left: 1rem;
    padding-right: 1rem;
  }

  .bguest__slideSix-body-contents .animation-tag {
    font-size: 12px;
  }

  .bguest__slideSix-body-animation {
    width: 55%;
    height: 70%;
  }

  .bguest__slideSix-body-contents-thumb-animations .temporary {
    display: none;
  }

  .bguest__slideSix-body-animation .router {
    top: -4rem;
    width: 100px;
    height: 100px;
  }

  .bguest__slideSix-body-animation .image1 {
    height: 100px;
  }

  .bguest__slideSix-body-animation .image2 {
    height: 250px;
  }
}

/* Mobile Media */
@media only screen and (max-width: 599px){
    .bguest__slideSix {
        width: 100%;
        height: auto;
        display: flex;
        flex-direction: column;
        overflow-y: scroll;
        overflow-x: hidden;
      }

    .bguest__slideSix-body {
        width: 100%;
        height: auto;
        padding-left: 1rem;
        padding-right: 1rem;
        display: flex;
        flex-direction: column;
      }

    .bguest__slideSix-body-contents{
        display: none;
    }

    .bguest__slideSix-body-contents-mobile{
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        width: 100%;
    }
    
    .bguest__slideSix-body-contents-mobile .animation-tag {
        width: 100%;
        font-size: 12px;
        font-family: "Poppins";
        font-weight: 400;
        color: var(--color-black);
        margin-top: 1rem;
        font-weight: lighter;
      }
    
      .bguest__slideSix-body-animation {
        width: 100%;
        height: auto;
        margin-bottom: 2rem;
        margin-top: 1rem;
        padding: 2rem;
      }

      .bguest__slideSix-body-contents-thumb-animations{
        padding-top: 1rem;
      }
    
      .bguest__slideSix-body-contents-thumb-animations .temporary {
        display: none;
      }
    
      .bguest__slideSix-body-animation .router {
        top: -4rem;
        width: 100px;
        height: 100px;
      }
    
      .bguest__slideSix-body-animation .image1 {
        height: 5rem;
      }
    
      .bguest__slideSix-body-animation .image2 {
        height: 15rem;
      }

      .bguest__slideSix-footer{
        display: none;
      }
}