.bguest__slideOne{
  display: flex;
  flex-direction: column;
}

.bguest__slideOne-body{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    width: 100%;
    height: 75vh;
    padding-left: 5rem;
    padding-right: 5rem;
}

.bguest__slideOne-body-contents{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    width: 40%;
    height: 100%;
}

.bguest__slideOne-body-animation{
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    width: 60%;
    height: 100%;
    flex-shrink: 0;
}

.bguest__slideOne-body-animation-cook-player{
    display: flex;
    justify-content: center;
    background-color: white;
    width: 400px;
    height: 400px;
    min-width: 400px;
    border-radius: 50%;
    overflow: hidden;
    margin-top: 5rem;
    z-index: 2;
}

.bguest__slideOne-body-animation-bowl-player{
    display: flex;
    justify-content: center;
    margin-top: 2rem;
    margin-left: -6rem;
    z-index: 3;
}

.bguest__slideOne-body-animation-bowl-player .bowl-player{
    width: 250px;
    height: 250px;
}

.bguest__slideOne-body-animation-video{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 600px;
    height: 600px;
    border-radius: 50%;
    overflow: hidden;
    margin-top: 10rem;
    margin-left: -24rem;
    z-index: 1;
}

.bguest__slideOne-body-animation-video video{
    object-fit: cover;
}

.bguest__slideOne-body-contents-process{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    margin-top: 1rem;
}

.bguest__slideOne-body-contents-process h1{
    font-family: "Poppins";
    font-size: 14px;
    font-weight: bold;
    color: var(--color-tagline);
}

.bguest__slideOne-body-contents-process .video-button{
    width: 80px;
    height: 80px;
    cursor: pointer;
}

.bguest__slideOne-body-contents-mobile{
    display: none;
}




/* Small Laptop Media */
@media only screen and (max-width: 1024px) and (min-width: 769px) {
    .bguest__slideOne-body{
        padding-left: 1rem;
        padding-right: 1rem;
    }

    .bguest__slideOne-body-animation-cook-player{
        width: 300px;
        height: 300px;
        min-width: 300px;
        margin-top: 8rem;
    }

    .bguest__slideOne-body-animation-bowl-player{
        margin-top: 4rem;
        margin-left: -5rem;
    }
    
    .bguest__slideOne-body-animation-bowl-player .bowl-player{
        width: 200px;
        height: 200px;
    }

    .bguest__slideOne-body-animation-video{
        width: 500px;
        height: 500px;
        margin-top: 12rem;
        margin-left: -24rem;
    }
}

/* Tablet Media */
@media only screen and (max-width: 768px) and (min-width: 600px) {
    .bguest__slideOne-body{
        padding-left: 1rem;
        padding-right: 1rem;
    }

    .bguest__slideOne-body-animation-cook-player{
        width: 200px;
        height: 200px;
        min-width: 200px;
        margin-top: 8rem;
    }

    .bguest__slideOne-body-animation-bowl-player{
        margin-top: 6rem;
        margin-left: -4rem;
    }
    
    .bguest__slideOne-body-animation-bowl-player .bowl-player{
        width: 120px;
        height: 120px;
    }

    .bguest__slideOne-body-animation-video{
        width: 350px;
        height: 350px;
        margin-top: 10rem;
        margin-left: -12rem;
    }
}

/* Mobile Media */
@media only screen and (max-width: 599px){
    .bguest__slideOne{
        width: 100%;
        height: auto;
        display: flex;
        flex-direction: column;
        overflow-y: scroll;
        overflow-x: hidden;
    }

    .bguest__slideOne-body{
        width: 100%;
        height: auto;
        padding-left: 1rem;
        padding-right: 1rem;
        display: flex;
        flex-direction: column;
    }

    .bguest__slideOne-body-contents{
        display: none;
    }

    .bguest__slideOne-body-contents-mobile{
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        width: 100%;
    }

    .bguest__slideOne-body-animation{
        align-items: flex-start;
        width: 100%;
        height: auto;
        margin-bottom: 2rem;
    }

    .bguest__slideOne-body-animation-cook-player{
        width: 180px;
        height: 180px;
        min-width: 180px;
        margin-top: 3rem;
    }

    .bguest__slideOne-body-animation-bowl-player{
        margin-top: 2rem;
        margin-left: -3rem;
    }
    
    .bguest__slideOne-body-animation-bowl-player .bowl-player{
        width: 100px;
        height: 100px;
    }

    .bguest__slideOne-body-animation-video{
        width: 300px;
        height: 300px;
        margin-top: 5rem;
        margin-left: -12rem;
    }

    .bguest__slideOne-body-contents-process h1{
        font-size: 12px;
    }

    .bguest__slideOne-body-contents-process .video-button{
        width: 50px;
        height: 50px;
    }

    .bguest__slideOne-footer{
        display: none;
    }
}
