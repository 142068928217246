.bguest__dotButton{
    cursor: pointer;
    display: inline-block;
    position: relative;
}

.bguest__dotButton:hover{
    display: block;
}

.bguest__dotButton-icon svg{
    font-size: 14px;
    display: flex;
    align-self: center;
    color: var(--color-brand);
}

.bguest__dotButton-menu{
    position: absolute;
    display: flex;
    right:0;
    bottom: 0;
    overflow: hidden;
    flex-direction: column;
    background-color: var(--color-bg-dark);
    border-radius: 12px;
    width: 200px;
    z-index: 1;
    border: 1px solid var(--color-bg-dark);
}

.bguest__dotButton-menu .menu{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    background-color: white;
    margin-top: 1px;
    padding: 0.5rem;
}

.bguest__dotButton-menu .menu:hover{
    background-color: var(--color-bg);
}

.bguest__dotButton-menu .menu h1{
    font-size: 14px;
    padding-top: 0;
    margin-left: 8px;
}