.bGuest__demo{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 5rem;
}

.bGuest__demo-button-animation-tablet{
    display: none;
}

.bGuest__demo-button-animation-mobile{
    display: none;
}

.bGuest__demo-subTitle h1{
    text-align: center;
    font-size: 18px;
}

/* Tablet Media */
@media only screen and (max-width: 768px) and (min-width: 600px) {
    .bGuest__demo{
        padding: 1rem;
    }

    .bGuest__demo-subTitle h1{
        font-size: 16px;
    }

    .bGuest__demo-button-animation{
        display: none;
    }

    .bGuest__demo-button-animation-tablet{
        display: block;
        padding-top: 1rem;
    }
}

/* Mobile Media */
@media only screen and (max-width: 599px){
    .bGuest__demo{
        padding: 1rem;
    }

    .bGuest__demo h1{
        text-align: center;
    }

    .bGuest__demo-subTitle h1{
        font-size: 14px;
    }

    .bGuest__demo-button-animation{
        display: none;
    }

    .bGuest__demo-button-animation-tablet{
        display: none;
    }

    .bGuest__demo-button-animation-mobile{
        display: block;
        padding-top: 1rem;
    }
}
