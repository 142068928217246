.bguest__uniqueGuestData{
    display: flex;
    flex-direction: column;
    width: 100%;
}

.bguest__uniqueGuestData-back{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 3rem;
    height: 3rem;
    padding: 12px;
    border-radius: 12px;
    background-color: var(--color-brand-light);
    margin-left: 2rem;
    margin-top: 2rem;
    cursor: pointer;
}

.bguest__uniqueGuestData-back svg{
    font-size: 2rem;
    color: var(--color-brand);
}

.bguest__uniqueGuestData-body{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.bguest__uniqueGuestData-body-info{
    min-width: 20vw;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-top: 1rem;
}

.bguest__uniqueGuestData-body-info .info{
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-right: 8px;
}

.bguest__uniqueGuestData-body-info .info svg{
    font-size: 14px;
    color: var(--color-brand);
    margin-right: 5px;
}

.bguest__uniqueGuestData-body-download{
    width: 80vw;
    display: flex;
    justify-content: flex-end;
    margin-top: 2rem;
}

.bguest__uniqueGuestData-body-table{
    width: 80vw;
    margin-top: 1rem;
    margin-bottom: 3rem;
    background-color: white;
    border-radius: 12px;
}
