.bguest__slideTwo{
  display: flex;
  flex-direction: column;
}

.bguest__slideTwo-body{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 75vh;
    padding-left: 5rem;
    padding-right: 5rem;
}

.bguest__slideTwo-body-contents{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    width: 40%;
    height: 100%;
}

.bguest__slideTwo-body-contents-thumb-animations{
    width: 100%;
    display: flex;
    flex-direction: row;
    overflow: hidden;
    justify-content: space-between;
    margin-top: 1rem;
}

.bguest__slideTwo-body-animation{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    width: 50%;
    height: 90%;
    flex-shrink: 0;
    background-color: white;
    border-radius: 12px;
    overflow: hidden;
}

.bguest__slideTwo-body-animation img{
    height: 40%;
}

.bguest__slideTwo-body-contents-mobile{
    display: none;
}



/* Small Laptop Media */
@media only screen and (max-width: 1024px) and (min-width: 768px)  {
    .bguest__slideTwo-body{
        padding-left: 1rem;
        padding-right: 1rem;
    }

    .bguest__slideTwo-body-contents-thumb-animations{
        margin-top: 2rem;
    }

    .bguest__slideTwo-body-animation{
        width: 55%;
        height: 70%;
    }
    
    .bguest__slideTwo-body-animation img{
        height: 35%;
    }
}

/* Tablet Media */
@media only screen and (max-width: 768px) and (min-width: 600px) {
    .bguest__slideTwo-body{
        padding-left: 1rem;
        padding-right: 1rem;
    }

    .bguest__slideTwo-body-contents-thumb-animations{
        margin-top: 2rem;
    }

    .bguest__slideTwo-body-animation{
        width: 55%;
        height: 70%;
    }
    
    .bguest__slideTwo-body-animation img{
        height: 35%;
    }
}

/* Mobile Media */
@media only screen and (max-width: 599px){
    .bguest__slideTwo{
        width: 100%;
        height: auto;
        display: flex;
        flex-direction: column;
        overflow-y: scroll;
        overflow-x: hidden;
      }


    .bguest__slideTwo-body{
        width: 100%;
        height: auto;
        padding-left: 1rem;
        padding-right: 1rem;
        display: flex;
        flex-direction: column;
    }

    .bguest__slideTwo-body-contents{
        display: none;
    }
    

    .bguest__slideTwo-body-contents-mobile{
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        width: 100%;
    }
    

    .bguest__slideTwo-body-contents-thumb-animations{
        margin-top: 1rem;
        width: 100%;
    }

    .bguest__slideTwo-body-animation{
        width: 100%;
        height: auto;
        margin-bottom: 2rem;
        margin-top: 1rem;
    }
    
    .bguest__slideTwo-body-animation img{
        height: 10rem;
    }


    .bguest__slideTwo-footer{
        display: none;
    }
}